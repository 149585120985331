import React, { useEffect } from "react";
import { genSaltSync, hashSync } from "bcryptjs";
import { checkEmailAddress, createEmailAddressForm } from "../utils";
import axios from "axios";
const formAPIURL = `${process.env.GATSBY_AI_SOLUTIONS_PAGE_FORM_URL}`;
const SERVER_URL = `${process.env.GATSBY_SITA_API_URL}`;

function removeTrailingSlash(url) {
    const regex = /\/$/;
    return url.replace(regex, "");
}
const SitaChatbotWrapper = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        document.body.appendChild(script);
        // cleanup
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined" && window.TarkaChat) {
            window.TarkaChat.init({
                title: "Smart Information and Task Assistant (SITA)",
                botName: "Sita",
                greeting: "Hello, I am Sita. I can help you answer movie related questions.",
                themeColor: "#F0DDFA",
                selectorId: "chatbot",
                expand: true,
                preChatRenderer: (onClose) => getPreChatScreen(onClose),
                submitHandler: (message) => onMessageSubmit(message),
            });
        }
    }, []);

    const submitEmailAddress = async (emailAddress) => {
        await axios.post(
            formAPIURL,
            {
                emailAddress,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        );
    };

    function getPreChatScreen(onClose) {
        async function onClick() {
            const emailAddress = document.getElementById("ai-solutions-email-input").value;
            if (checkEmailAddress(emailAddress)) {
                await submitEmailAddress(emailAddress);
                localStorage.setItem("email-collected", true);
                await chatbotSetup();
            } else {
                errorDiv.innerText = "Please enter a valid email address.";
            }
        }

        // const chatbotSetup = async () => {
        //     const salt = genSaltSync();
        //     const passkey = hashSync("apple", salt);
        //     const sessionId = await authorize(passkey);
        //     // const llmName = await fetchLlmModels();
        //     const llmName = "gpt-4-0125-preview";
        //     const llm_model_name = localStorage.setItem(
        //         "sita-bot-configs",
        //         JSON.stringify({
        //             llmName: "",
        //             passkey: passkey,
        //         })
        //     );
        //     // showLlmModelsName(llmName, passkey, sessionId, llm_model_name);
        //     selectLlmModel(llmName, sessionId, passkey, llm_model_name);
        // };

        const chatbotSetup = async () => {
            const passkey = "apple";
            const salt = genSaltSync();
            const authToken = hashSync(passkey, salt);
            localStorage.setItem("authToken", authToken);
            const isAuthorized = await authorize(authToken);
            if (!isAuthorized) {
                alert("Authorization failed. Please check the passkey.");
                return;
            }
            const llmName = "gpt-4-0125-preview";
            localStorage.setItem(
                "sita-bot-configs",
                JSON.stringify({
                    llmName: llmName,
                    passkey: passkey,
                    authToken: authToken,
                })
            );
            selectLlmModel(llmName);
        };

        async function showLlmModelsName(llmName, passkey, sessionId) {
            const prechatContainer = document.getElementById("prechat-container");
            prechatContainer.innerHTML = ""; // Remove existing content
            const llmModelContainer = document.createElement("div");
            llmModelContainer.classList.add("prechat-silos");
            // Create h4 element
            const h4 = document.createElement("h4");
            h4.textContent = "Which language model you would like to use?";
            llmModelContainer.appendChild(h4);
            // Create ul element
            const ul = document.createElement("ul");
            // Loop through silos and create li elements with buttons
            llmName.forEach((item) => {
                const li = document.createElement("li");
                const button = document.createElement("button");
                button.textContent = item;
                button.onclick = () => selectLlmModel(item, passkey, sessionId);
                li.appendChild(button);
                ul.appendChild(li);
            });
            llmModelContainer.appendChild(ul);
            prechatContainer.appendChild(llmModelContainer);
        }
        function selectLlmModel(llmName, passkey, sessionId) {
            // Handle selection of silo here
            console.log("Selected silo:", llmName);
            localStorage.setItem(
                "sita-bot-configs",
                JSON.stringify({
                    passkey: passkey,
                    sessionId: sessionId,
                    llmName,
                })
            );
            onClose();
        }

        const container = document.createElement("div");
        container.id = "prechat-container";

        if (localStorage.getItem("email-collected")) {
            const startButton = document.createElement("button");
            startButton.id = "start-chat-action";
            startButton.textContent = "Click here to start chatting.";
            startButton.onclick = chatbotSetup;
            container.appendChild(startButton);
            return container;
        }

        const { form, errorDiv } = createEmailAddressForm(onClick);
        container.appendChild(form);
        return container;
    }
    // async function authorize(passkey) {
    //     try {
    //         const url = SERVER_URL + "/api/check-auth";
    //         const response = await fetch(url, {
    //             headers: { Authorization: "Bearer " + passkey },
    //         });
    //         if (response.status === 401) {
    //             throw new Error("Failed to authorize");
    //         }
    //         return true;
    //     } catch (err) {
    //         return false;
    //     }
    // }

    async function authorize(authToken) {
        try {
            // const url = `${SERVER_URL}/api/check-auth`;
            const url = removeTrailingSlash(SERVER_URL) + "/api/check-auth";
            const response = await fetch(url, {
                method: "GET",
                headers: { Authorization: "Bearer " + authToken },
            });
            if (response.status === 401) {
                return false;
            }
            return true;
        } catch (err) {
            console.error("Authorization error:", err);
            return false;
        }
    }

    async function fetchLlmModels() {
        try {
            const configs = localStorage.getItem("sita-bot-configs");
            const { passkey } = JSON.parse(configs);
            // const url = SERVER_URL + "/api/available-llms";
            const url = removeTrailingSlash(SERVER_URL) + "/api/available-llms";
            const response = await fetch(url, {
                headers: { Authorization: "Bearer " + passkey },
            });
            if (response.status === 200) {
                return response.json();
            }
            return [];
        } catch (err) {
            return [];
        }
    }
    async function onMessageSubmit(question) {
        const authToken = localStorage.getItem("authToken");
        // const url = `${SERVER_URL}/api/chat`;
        const url = removeTrailingSlash(SERVER_URL) + "/api/chat";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authToken,
            },
            body: JSON.stringify({ question }),
        });
        if (response.status === 200) {
            const responseData = await response.json();
            if (responseData.steps) {
                responseData.steps.forEach((step) => {
                    console.log(step);
                });
            }
            if (responseData.costing) {
                console.log(responseData.costing);
            }
            console.log("llm model = ", responseData.llm_model);
            return Promise.resolve(responseData.output);
        } else {
            return Promise.resolve("Oops! There seem to be some internal issue :(");
        }
    }
    return <div id="chatbot"></div>;
};
export default SitaChatbotWrapper;
